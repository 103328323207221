/* General Styles */
.chrome-extension-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: #333;
  margin-top: 100px;
}

.extension-header {
  background-color: #f4f4f9;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.extension-header h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #0056b3;
}

.extension-header p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  color: #666;
}

.download-btn {
  background-color: #0056b3;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.download-btn:hover {
  background-color: #0041a8;
}

/* Features Section */
.features-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 40px;
}

.feature-card {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  text-align: center;
  transition: all 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.feature-icon {
  font-size: 3rem;
  color: #0056b3;
  margin-bottom: 20px;
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.feature-card p {
  color: #777;
  font-size: 1rem;
}

/* Footer Section */
.extension-footer {
  background-color: #f4f4f9;
  padding: 30px;
  border-radius: 10px;
  margin-top: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.install-btn {
  background-color: #28a745;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.install-btn:hover {
  background-color: #218838;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .chrome-extension-container {
    padding: 10px;
    margin-top: 50px;
  }

  .extension-header {
    padding: 20px;
  }

  .extension-header h2 {
    font-size: 2rem;
  }

  .extension-header p {
    font-size: 1rem;
  }

  .download-btn {
    font-size: 0.9rem;
    padding: 10px 20px;
  }

  .features-section {
    flex-direction: column;
  }

  .feature-card {
    margin-bottom: 20px;
  }

  .feature-card h3 {
    font-size: 1.3rem;
  }

  .feature-card p {
    font-size: 0.9rem;
  }

  .install-btn {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}

/* Tablet Styles */
@media (min-width: 600px) and (max-width: 1024px) {
  .chrome-extension-container {
    max-width: 90%;
  }

  .extension-header {
    padding: 30px;
  }

  .features-section {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .feature-card {
    width: 45%;
    margin-bottom: 20px;
  }

  .feature-card h3 {
    font-size: 1.4rem;
  }

  .feature-card p {
    font-size: 1rem;
  }

  .install-btn {
    font-size: 1rem;
    padding: 12px 24px;
  }
}