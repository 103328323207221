/* Navbar styles */
.navbar-container {
  position: relative;
  width: 100%;
}

.navbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: white;
  z-index: 1050;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for styling */
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo .logo {
  height: 50px;
  margin-right: 20px;
}

.navbar h1 {
  font-size: 1.5rem;
  color: #123456;
}

.navbar-links {
  display: flex;
  gap: 20px;
}

.navbar-links a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}
.navbar-title {
  text-decoration: none; /* Remove underline from the link */
}

.navbar-title h1 {
  margin: 0;
  font-size: 1.5rem;
  /* color: inherit; Keeps the font color unchanged */
}

.navbar-title:hover {
  text-decoration: none; /* Ensures underline doesn't appear on hover */
}

.navbar-links a:hover {
  background-color: #1a1a89;
  color: white;
}

/* Hide the toggle menu button by default (for desktop views) */
.menu-toggle {
  display: none;
}

/* Mobile styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    text-align: center;
    
  }

  .navbar-links {
    display: none; /* Hidden by default */
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .navbar-links.open {
    display: flex; /* Show when open */
  }

  .navbar-links a {
    padding: 15px;
    font-size: 1rem;
  }

  /* Show the toggle button only on mobile devices */
  .menu-toggle {
    display: block;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
  }
}

/* Smaller screen adjustments */
@media (max-width: 480px) {
  .navbar h1 {
    font-size: 1.2rem;
  }

  .navbar-logo .logo {
    height: 40px;
  }
}