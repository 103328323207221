.scroll-to-top-button {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 24px;
    font-size: 18px;
    background-color: #1d72b8;
    color: #fff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.4s ease;
}

.scroll-to-top-button:hover {
    background-color: #155a8a;
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
    transform: translateX(-50%) scale(1.1);
}

.scroll-to-top-button.hidden {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
}
